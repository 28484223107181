import React, { useEffect } from "react";
import { scrollToTop } from "../../../utils/utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CreateChat, token } from "../../../component/Api/api";

export const UIndividualView = ({
  Page,
  setPage,
  Lists,
  setLists,
  View,
  setView,
  setloading,
}) => {
  const current_store = useSelector((state) => state.checktoken_data);
  const nav = useNavigate();
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="uindi_view_section">
      <div className="uindi_view_image">
        <img
          src={
            View?.picture ||
            "https://access-files.sfo2.digitaloceanspaces.com/images/17216567670051721656767001.webp"
          }
        />
      </div>
      <div className="uindi_views_title">{View?.name}</div>
      <div className="uindi_views_type">{View?.type}</div>
      <div className="uindi_views_title1">Individual Name</div>
      <div className="uindi_views_desc">{View?.name}</div>
      <div className="uindi_views_title1">Phone</div>
      <div className="uindi_views_desc">{View?.phone}</div>
      <div className="uindi_views_title1">Email</div>
      <div className="uindi_views_desc">{View?.email}</div>
      <div className="uindi_views_title1">Gender</div>
      <div className="uindi_views_desc">{View?.gender}</div>
      <div className="uindi_views_title1">Birthdate</div>
      <div className="uindi_views_desc">{View?.dob}</div>
      <div className="uindi_views_title1">Address</div>
      <div className="uindi_views_desc">
        {View?.city}, {View?.state} , {View.zipcode}
      </div>

      <div className="uindi_views_title1">
        Do you have a criminal background?
      </div>
      <div className="uindi_views_desc">
        {View?.criminalBackground ? "Yes" : "No"}
      </div>
      <div className="uindi_views_title1">
        Are you currently on parole/probation?
      </div>
      <div className="uindi_views_desc">
        {View?.paroleProbation ? "Yes" : "No"}
      </div>
      <div className="uindi_views_title1"> Are you registered to vote?</div>
      <div className="uindi_views_desc">
        {View?.registeredToVote ? "Yes" : "No"}
      </div>
      <div className="uindi_views_title1">
        Have you been pregnant and incarcerated?
      </div>
      <div className="uindi_views_desc">
        {View?.pregnantOrIncarcerated ? "Yes" : "No"}
      </div>
      <div className="uindi_views_title1">
        Have you ever been in solitary confinement?
      </div>
      <div className="uindi_views_desc">
        {View?.solitaryConfinement ? "Yes" : "No"}
      </div>

      {View?.solitaryConfinement ? (
        <>
          <div className="uindi_views_title1">
            Enter the amount of time you have spent in confinement
          </div>
          {View?.solitaryConfinementExtra?.number ? (
            <div className="uindi_views_desc">
              {View?.solitaryConfinementExtra?.number +
                " " +
                View?.solitaryConfinementExtra?.duration}
            </div>
          ) : (
            "-"
          )}
        </>
      ) : (
        ""
      )}
      <div className="uindi_views_title1">Are you a veteran?</div>
      <div className="uindi_views_desc">{View?.veteran ? "Yes" : "No"}</div>
      {View?.veteran ? (
        <>
          <div className="uindi_views_title1">
            What branch were you a member of?
          </div>
          <div className="uindi_views_desc">{View?.veteranPlace || "-"}</div>
        </>
      ) : (
        ""
      )}
      <div className="uindi_views_title1">What services are you seeking?</div>
      <div className="uindi_views_list">
        {View?.servicesSeeking?.map((el, i) => (
          <div key={i}>{el}</div>
        ))}
      </div>
      <div className="uindi_views_title1">Life Experience</div>
      <div className="uindi_views_desc">{View?.lifeExperience}</div>
      <div
        className="uindi_views_btns"
        onClick={() => {
          setloading(true);
          CreateChat(
            {
              sender: current_store?.userdetail?.id,
              receiver: View.id,
            },
            token,
            ({ status, response }) => {
              if (response.status) {
                nav(`/message?${response?.chat?.chat?._id}`);
              }
            }
          );
        }}
      >
        <svg
          width="25"
          height="25"
          viewBox="0 0 28 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 27.1875C6.76801 27.1873 6.54558 27.0917 6.38154 26.9218C6.21749 26.7519 6.12523 26.5215 6.125 26.2812V21.75H3.5C2.05275 21.75 0.875 20.5302 0.875 19.0312V4.53125C0.875 3.03231 2.05275 1.8125 3.5 1.8125H24.5C25.9473 1.8125 27.125 3.03231 27.125 4.53125V19.0312C27.125 20.5302 25.9473 21.75 24.5 21.75H13.8696L7.54688 26.989C7.39171 27.1177 7.19879 27.1877 7 27.1875ZM3.5 3.625C3.017 3.625 2.625 4.03191 2.625 4.53125V19.0312C2.625 19.5306 3.017 19.9375 3.5 19.9375H7C7.23206 19.9375 7.45462 20.033 7.61872 20.2029C7.78281 20.3729 7.875 20.6034 7.875 20.8438V24.3962L13.0156 20.136C13.1709 20.0074 13.3637 19.9374 13.5625 19.9375H24.5C24.983 19.9375 25.375 19.5306 25.375 19.0312V4.53125C25.375 4.03191 24.983 3.625 24.5 3.625H3.5Z"
            fill="white"
          ></path>
        </svg>
        Start Chatting
      </div>
    </div>
  );
};

import React, { useEffect } from "react";
import { CreateProfileNav } from "../../../component/Navbar/CreateProfileNav";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CheckToken, token } from "../../../component/Api/api";

export const RequestProcessing = ({ setloading }) => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const current_store = useSelector((state) => state.checktoken_data);
  const token = localStorage.getItem("user_token");
  useEffect(() => {
    setloading(false);
    if (current_store?.token || token) {
      CheckToken(current_store?.token || token, ({ status, response }) => {
        if (response.status) {
          if (response?.user?.type) {
            setloading(false);
            dispatch({
              type: "setCheckToken",
              payload: {
                token: current_store?.token || token,
                userdetail: response.user,
                user_type: response?.user?.type,
              },
            });
            if (
              response?.user?.directlyImpactedOrg &&
              response?.user?.dioApproval
            ) {
              localStorage.setItem("userType", response.user.type);
              nav("/organization");
            }
          } else {
            nav("/welcome");
          }
        } else {
          setloading(false);
          nav("/login");
        }
      });
    } else {
      setloading(false);
      nav("/login");
    }
  }, []);

  return (
    <>
      <CreateProfileNav
        {...{
          title: "",
          btn1: {
            name: (
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.8973 13.5435H4.59813L8.02727 16.9686C9.01152 17.9529 7.53514 19.4332 6.55089 18.449L3.42884 15.323L1.34223 13.2364C1.14749 13.0408 1.03816 12.7761 1.03816 12.5001C1.03816 12.2242 1.14749 11.9595 1.34223 11.7639L6.55483 6.55133C6.65307 6.45056 6.77068 6.37071 6.90059 6.31658C7.03049 6.26246 7.17001 6.23517 7.31073 6.23637C7.51858 6.23691 7.72153 6.29952 7.89355 6.41618C8.06557 6.53283 8.19883 6.6982 8.27625 6.8911C8.35366 7.08399 8.37169 7.29561 8.32803 7.49882C8.28437 7.70203 8.18101 7.88757 8.03121 8.03164L4.5942 11.4568H22.9525C24.3973 11.5316 24.291 13.6143 22.8973 13.5435Z"
                  fill="#D0D0D1"
                />
              </svg>
            ),
            clickback: () => {
              localStorage.clear();
              dispatch({
                type: "setCheckToken",
                payload: {
                  token: "",
                  userdetail: "",
                  user_type: "",
                },
              });
              nav("/login");
            },
          },
          btn2: {
            name: "",
            clicknext: () => {},
          },
        }}
      />
      <div className="request_processing">
        <div className="request_processing_svg">
          <svg
            width="85"
            height="85"
            viewBox="0 0 85 85"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.7" clipPath="url(#clip0_1_531)">
              <path
                d="M50.8271 55.7074L52.916 57.797L55.358 60.2263L56.299 61.1741L61.1716 56.298L55.7082 50.8262L50.8271 55.7074Z"
                fill="#5D6D7E"
              />
              <path
                d="M66.141 56.2656L82.9558 73.0795C84.2206 74.3409 85.0056 76.0919 85.0004 78.0177C85.0004 79.9468 84.2205 81.6961 82.9558 82.9626C81.6944 84.2223 79.9476 84.9996 78.0185 84.9996C77.1015 85.0015 76.1931 84.8221 75.3457 84.4718C74.4982 84.1214 73.7284 83.6071 73.0803 82.9583L56.2666 66.1427L66.141 56.2656Z"
                fill="#D83A35"
              />
              <path
                d="M63.8203 58.5897L65.1866 57.2207L82.003 74.0371C83.0647 75.0996 83.6486 76.5127 83.6477 78.0168C83.6477 79.5251 83.062 80.9408 82.003 82.005C81.8193 82.1831 81.5737 82.2832 81.3179 82.2842C81.0605 82.2847 80.8133 82.1835 80.6302 82.0025C80.2559 81.623 80.2559 81.0152 80.6302 80.6387C81.3315 79.9399 81.7148 79.0041 81.7148 78.0143C81.717 77.5294 81.6227 77.049 81.4374 76.6009C81.252 76.1528 80.9794 75.7461 80.6354 75.4044L63.8203 58.5897Z"
                fill="#FF817D"
              />
              <path
                d="M56.2654 66.1428L66.1406 56.2648L65.4042 55.5266C65.178 55.2985 64.9088 55.1176 64.6122 54.9944C64.3155 54.8713 63.9973 54.8083 63.6761 54.8092C62.9961 54.8092 62.3867 55.0792 61.9464 55.5233L55.5246 61.9484C55.2966 62.1746 55.1159 62.4438 54.9929 62.7405C54.8699 63.0371 54.807 63.3553 54.8081 63.6764C54.8077 63.9967 54.8708 64.3139 54.9938 64.6097C55.1168 64.9054 55.2972 65.1738 55.5246 65.3994L56.2654 66.1428ZM0 31.5699C0 14.1372 14.1351 0.000488281 31.5712 0.000488281C49.009 0.000488281 63.1432 14.138 63.1432 31.5699C63.1432 49.0076 49.0073 63.1419 31.5712 63.146C14.1351 63.1419 0 49.0071 0 31.5699Z"
                fill="#4C5ABA"
              />
              <path
                d="M31.5712 59.725C47.1161 59.725 59.7245 47.1212 59.7245 31.5692C59.7245 16.0262 47.1165 3.41846 31.5712 3.41846C16.0239 3.41879 3.41699 16.0257 3.41699 31.5696C3.41699 47.1212 16.0239 59.725 31.5712 59.725Z"
                fill="#5D6D7E"
              />
              <path
                d="M31.5717 57.4716C45.8734 57.4716 57.4711 45.8784 57.4711 31.5695C57.4711 17.2712 45.8729 5.6709 31.5717 5.6709C17.2704 5.6709 5.6709 17.2712 5.6709 31.5695C5.6709 45.8776 17.2674 57.4716 31.5717 57.4716Z"
                fill="#CEE2F2"
              />
              <path
                d="M32.2523 9.92324C31.8112 9.26881 31.053 8.85908 30.2186 8.90656C26.6435 9.12371 23.2647 10.1306 20.1771 11.9232C19.0901 12.5591 18.7172 13.9602 19.3506 15.0525C19.9837 16.1311 21.3867 16.5149 22.4705 15.8731C24.9333 14.4523 27.6291 13.6451 30.4869 13.471C31.7423 13.4056 32.708 12.3227 32.6301 11.0681C32.6157 10.6333 32.4699 10.2423 32.2523 9.92324ZM17.5876 17.4321C17.4661 17.2498 17.3076 17.0807 17.1337 16.9366C16.1533 16.1345 14.7156 16.2906 13.9228 17.2703C11.5897 20.1347 9.98395 23.5714 9.27507 27.2289C8.43652 31.5728 8.87513 36.0204 10.5408 40.0957C11.0262 41.2705 12.3623 41.8221 13.5277 41.3419C14.6965 40.8684 15.2533 39.5373 14.7747 38.3625C13.4465 35.1126 13.1019 31.5634 13.7764 28.0963C14.3281 25.1766 15.6107 22.4353 17.4718 20.1493C18.1189 19.3534 18.136 18.2366 17.5876 17.4321Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_531">
                <rect width="85" height="85" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="request_processing_desc">
          We are processing your request and waiting for the admin’s
          confirmation. You will receive an email from us as soon as your
          request is approved. This may take up to 48 hours, so please be
          patient.
        </div>
        <div className="request_processing_contactto">
          For any query write us at <span>info@weareit.guru</span>
        </div>
        <div
          className="leave_app_btn"
          onClick={() => {
            dispatch({
              type: "setCheckToken",
              payload: {
                token: "",
                userdetail: "",
                user_type: "",
              },
            });
            localStorage.clear();
            nav("/login");
          }}
        >
          Leave app
        </div>
      </div>
    </>
  );
};

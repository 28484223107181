import React, { useEffect, useState } from "react";
import { CustomNavbar } from "../../../component/Navbar/CustomNavbar";
import { BottomNav } from "../../../component/Navbar/BottomNav";
import { UOrgabizationAllPages } from "./UOrgabizationAllPages";
import {
  CheckToken,
  OrganizationList,
  token,
  UserOrganizationList,
  usertype,
} from "../../../component/Api/api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkPlan } from "../../../utils/utils";

export const UOrganization = ({ setloading }) => {
  const token = localStorage.getItem("user_token");
  const current_store = useSelector((state) => state.checktoken_data);
  const dispatch = useDispatch();
  const nav = useNavigate();

  const [FilterObj, setFilterObj] = useState({
    Services: ["All"],
    empty: ["All"],
  });
  const [CurrentFilter, setCurrentFilter] = useState({
    Services: ["All"],
    empty: ["All"],
  });
  const [Page, setPage] = useState("orgmaps");
  const [Lists, setLists] = useState([]);
  const [View, setView] = useState({});

  useEffect(() => {
    setloading(true);
    if (current_store?.token || token) {
      CheckToken(current_store?.token || token, ({ status, response }) => {
        if (response.status) {
          if (response?.user?.type) {
            if (
              response?.user?.type == "organization" ||
              response?.user?.type == "individual"
            ) {
              dispatch({
                type: "setCheckToken",
                payload: {
                  token: current_store?.token || token,
                  userdetail: response.user,
                  user_type: response?.user?.type,
                },
              });

              checkPlan({
                ...response.user,
                ongoto: () => {
                  nav("/checkout");
                },
                ongopending: () => {
                  nav("/requestprocessing");
                },
              });
            } else {
              nav("/admin/dashboard");
            }
          } else {
            nav("/welcome");
          }
        } else {
          setloading(false);
          nav("/login");
        }
      });
    } else {
      setloading(false);
      nav("/login");
    }
  }, []);

  useEffect(() => {
    if (CurrentFilter?.empty?.length == 0) {
      setCurrentFilter((prev) => ({
        ...prev,
        empty: ["All"],
      }));
    }
    if (CurrentFilter?.empty.length > 1) {
      if (CurrentFilter?.empty[0] == "All") {
        setCurrentFilter((prev) => ({
          ...prev,
          empty: [...prev.empty.filter((el) => el != "All")],
        }));
      } else if (CurrentFilter?.empty.reverse()[0] == "All") {
        setCurrentFilter((prev) => ({
          ...prev,
          empty: ["All"],
        }));
      }
    }
  }, [CurrentFilter]);
  useEffect(() => {
    OrganizationList(current_store?.token || token, ({ status, response }) => {
      if (response.status) {
        setLists(response?.organizations?.filter((el) => el.active));
        setloading(false);
      }
    });
  }, []);

  return (
    <>
      {!(Page == "orgmessage" || Page == "orgindimessage") ? (
        <CustomNavbar
          {...{
            filter_option: true,
            type: "",
            menu_ctr: ["orgmaps", "list"].includes(Page) ? true : false,
            setFilterObj,
            FilterObj,
            CurrentFilter,
            setCurrentFilter,
            list: {
              data: [
                {
                  title: "empty",
                  multiselect: true,
                  options: [
                    "All",

                    "Employment",
                    "Housing",
                    "Food",
                    "Clothing",
                    "Mental Health",
                    "Health/Wellness",
                    "Healthcare",
                    "Reproductive Health",
                    "Skills Training",
                    "Legal Services",
                    "Childcare",
                    "Diapers",
                    "Baby Formula",
                    "Feminine Hygiene Products",
                    "Higher Education",
                    "Support Groups",
                    "Utility Assistance",
                    "Furniture",
                    "Hygiene Products",
                    "Dentist",
                    "Identification",
                    "Transportation",
                    "Financial Services",
                    "Literacy",
                    "Insurance",
                    "Reentry Program",
                    "Voting Rights",
                    "Probation Reform GA",
                    "Medical",
                    "Carpentry",
                  ],
                },
              ],
            },
            btn1: {
              txt: !["orgmaps", "list"].includes(Page) ? (
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.8973 13.5435H4.59813L8.02727 16.9686C9.01152 17.9529 7.53514 19.4332 6.55089 18.449L3.42884 15.323L1.34223 13.2364C1.14749 13.0408 1.03816 12.7761 1.03816 12.5001C1.03816 12.2242 1.14749 11.9595 1.34223 11.7639L6.55483 6.55133C6.65307 6.45056 6.77068 6.37071 6.90059 6.31658C7.03049 6.26246 7.17001 6.23517 7.31073 6.23637C7.51858 6.23691 7.72153 6.29952 7.89355 6.41618C8.06557 6.53283 8.19883 6.6982 8.27625 6.8911C8.35366 7.08399 8.37169 7.29561 8.32803 7.49882C8.28437 7.70203 8.18101 7.88757 8.03121 8.03164L4.5942 11.4568H22.9525C24.3973 11.5316 24.291 13.6143 22.8973 13.5435Z"
                    fill="#D0D0D1"
                  />
                </svg>
              ) : (
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 7.99935C4 7.64573 4.14048 7.30659 4.39052 7.05654C4.64057 6.80649 4.97971 6.66602 5.33333 6.66602H26.6667C27.0203 6.66602 27.3594 6.80649 27.6095 7.05654C27.8595 7.30659 28 7.64573 28 7.99935C28 8.35297 27.8595 8.69211 27.6095 8.94216C27.3594 9.19221 27.0203 9.33268 26.6667 9.33268H5.33333C4.97971 9.33268 4.64057 9.19221 4.39052 8.94216C4.14048 8.69211 4 8.35297 4 7.99935ZM4 23.9993C4 23.6457 4.14048 23.3066 4.39052 23.0565C4.64057 22.8065 4.97971 22.666 5.33333 22.666H12C12.3536 22.666 12.6928 22.8065 12.9428 23.0565C13.1929 23.3066 13.3333 23.6457 13.3333 23.9993C13.3333 24.353 13.1929 24.6921 12.9428 24.9422C12.6928 25.1922 12.3536 25.3327 12 25.3327H5.33333C4.97971 25.3327 4.64057 25.1922 4.39052 24.9422C4.14048 24.6921 4 24.353 4 23.9993Z"
                    fill="#D0D0D1"
                  />
                  <path
                    d="M4.39052 15.3905C4.14048 15.6406 4 15.9797 4 16.3333C4 16.687 4.14048 17.0261 4.39052 17.2761C4.64057 17.5262 4.97971 17.6667 5.33333 17.6667H18.6667C19.0203 17.6667 19.3594 17.5262 19.6095 17.2761C19.8595 17.0261 20 16.687 20 16.3333C20 15.9797 19.8595 15.6406 19.6095 15.3905C19.3594 15.1405 19.0203 15 18.6667 15H5.33333C4.97971 15 4.64057 15.1405 4.39052 15.3905Z"
                    fill="#D0D0D1"
                  />
                </svg>
              ),
              onclick: () => {
                if (Page == "orgviews" || Page == "orgviewsIndi") {
                  setPage("list");
                } else if (Page == "orgindlist") {
                  setPage("orgviews");
                } else if (Page == "orgindview") {
                  setPage("orgindlist");
                }
              },
            },
            txt: [
              {
                name: "Current Employees",
                isto: "orgindlist",
              },
              {
                name: "Organizations List",
                isto: "list",
              },
              {
                name: "Organization",
                isto: "orgmaps",
              },
              {
                name: "",
                isto: "orgviews",
              },
            ].filter((el, i) => Page == el?.isto)[0]?.name,
            btn2: {
              txt: !["orgmaps", "list"].includes(Page) ? (
                ""
              ) : (
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1_5372)">
                    <path
                      d="M0.78125 4.40757H12.676C13.0345 6.03936 14.4917 7.2644 16.2296 7.2644C17.9675 7.2644 19.4248 6.0394 19.7833 4.40757H24.2188C24.6502 4.40757 25 4.05776 25 3.62632C25 3.19487 24.6502 2.84507 24.2188 2.84507H19.7829C19.4237 1.21411 17.9645 -0.0117188 16.2296 -0.0117188C14.4939 -0.0117188 13.0354 1.21392 12.6763 2.84507H0.78125C0.349805 2.84507 0 3.19487 0 3.62632C0 4.05776 0.349805 4.40757 0.78125 4.40757ZM14.1542 3.62837L14.1542 3.61997C14.1576 2.479 15.0886 1.55083 16.2296 1.55083C17.369 1.55083 18.3001 2.47773 18.305 3.61816L18.3052 3.62964C18.3034 4.77256 17.373 5.70195 16.2296 5.70195C15.0868 5.70195 14.1567 4.77349 14.1541 3.63125L14.1542 3.62837ZM24.2188 20.5932H19.7829C19.4236 18.9623 17.9645 17.7364 16.2296 17.7364C14.4939 17.7364 13.0354 18.9621 12.6763 20.5932H0.78125C0.349805 20.5932 0 20.9429 0 21.3744C0 21.8059 0.349805 22.1557 0.78125 22.1557H12.676C13.0345 23.7874 14.4917 25.0125 16.2296 25.0125C17.9675 25.0125 19.4248 23.7874 19.7833 22.1557H24.2188C24.6502 22.1557 25 21.8059 25 21.3744C25 20.9429 24.6502 20.5932 24.2188 20.5932ZM16.2296 23.45C15.0868 23.45 14.1567 22.5215 14.1541 21.3793L14.1542 21.3765L14.1542 21.3681C14.1576 20.2271 15.0886 19.2989 16.2296 19.2989C17.369 19.2989 18.3001 20.2258 18.305 21.3662L18.3052 21.3776C18.3035 22.5207 17.3731 23.45 16.2296 23.45ZM24.2188 11.7191H12.324C11.9655 10.0874 10.5083 8.86235 8.77036 8.86235C7.03247 8.86235 5.57524 10.0874 5.21675 11.7191H0.78125C0.349805 11.7191 0 12.0689 0 12.5004C0 12.9319 0.349805 13.2816 0.78125 13.2816H5.21714C5.57637 14.9125 7.03555 16.1384 8.77036 16.1384C10.5061 16.1384 11.9646 14.9127 12.3237 13.2816H24.2188C24.6502 13.2816 25 12.9319 25 12.5004C25 12.0689 24.6502 11.7191 24.2188 11.7191ZM10.8458 12.4983L10.8458 12.5067C10.8424 13.6477 9.91138 14.5759 8.77036 14.5759C7.63096 14.5759 6.6999 13.649 6.69497 12.5086L6.69482 12.4972C6.69653 11.3541 7.62695 10.4249 8.77036 10.4249C9.91323 10.4249 10.8433 11.3533 10.8459 12.4956L10.8458 12.4983Z"
                      fill="#D0D0D1"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_5372">
                      <rect width="25" height="25" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              ),
              onclick: () => {},
            },
          }}
        />
      ) : (
        ""
      )}
      <div className="uorganization_container">
        <UOrgabizationAllPages
          {...{
            Page,
            setPage,
            otherStates: {
              Lists,
              setLists,
              View,
              setView,
              FilterObj,
              setloading,
            },
          }}
        />
      </div>

      {!(Page == "orgmessage" || Page == "orgindimessage") ? (
        <BottomNav type={""} />
      ) : (
        ""
      )}
    </>
  );
};
